


// TEST DE OBTENER DATOS DE OBJETO
//
//function fnVerDatosIdioma(idioma){
//  console.log("IDIOMA: "+idioma);
//  console.log(ObjIdiomas[idioma].urlimg);
//  console.log(ObjIdiomas[idioma].palabras);
//
//  for (var i=0;i < ObjIdiomas[idioma].palabras.length;i++){
//    console.log(ObjIdiomas[idioma].palabras[i]);
//  };
//};
//
//var varidioma = "es";
//
//fnVerDatosIdioma(varidioma);





