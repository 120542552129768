$(document).foundation();


//=============================================
//  OBJETO IDIOMAS
//=============================================


var ObjIdiomas = {
  es :{
    urlimg: "assets/img/flag-es.png",
    palabras : ["Hola", "Adiós","¿Cómo estás?","Bien","¿Qué estás haciendo?","Dibujando","¿Cómo te llamas?","¿Cuáles son tus hobbies?","Cocinar","¿En qué grado estás?","8vo grado","¿Cómo se llama tu colegio?","Colegio Mills Park","¿Cuál es tu deporte favorito?","Rugby"]
  },
  en : {
    urlimg: "assets/img/flag-en.png",
    palabras : ["Hi","Bye","How are you?","Good","What are you doing?","Drawing","What is your name?","What are your hobbies?","Cook","What grade are you in?","8th grade","What is your school's name?","Mills Park school","What is your favorite sport?","Rugby"]
  },
  fr: {
    urlimg: "assets/img/flag-fr.png",
    palabras : ["Salut","Au revoir","Bonne","Comment allez-vous?","Dessin","Qu'est-ce que tu fais?","Comment vous appelez-vous?","Quels sont vos hobbies?","Cuisiner","En quelle classe es-tu?","8e année","Quel est le nom de votre école?","École Mills Park","Quel est ton sport préféré?","Rugby"]
  }

};



//=============================================
//  Crear tabla con "palabras"
//=============================================

function fnTablaPalabras() {
  var bufferText = '';
  for (var i=0; i< ObjIdiomas['es'].palabras.length; i++ ){

    bufferText += "<tr><td>"+ObjIdiomas['en'].palabras[i]+"</td><td>"+ObjIdiomas['es'].palabras[i]+"</td><td>"+ObjIdiomas['fr'].palabras[i]+"</td></tr>";
  };
  return bufferText;
};

$('#palabras').find('tbody').append(fnTablaPalabras());




//=============================================
//  FN LOG TEMPORAL
//=============================================

function fnLog(reset,logText){

  if (reset) {$( "#log" ).html('');};

  var logTextActual = $( "#log" ).html();
  logTextActual = logTextActual + '<p>' + logText + '</p>';
  $( "#log" ).html( logTextActual );
};



//=============================================
//  REINICIAR PAGINA
//=============================================


$('#btn-refresh').on('click tap', function() {
  location.reload();
});

//=============================================
//  OBTENER EL OTRO MOVIL
//=============================================

function fnOtroMovil(movil) {
  var otromovil = (movil == "iphone") ? "android" : "iphone";
  return otromovil;
  //  console.log(otromovil);
}


//=============================================
//  SCROLL DE CHAT
//=============================================


// fn bajar scrol del chat
function fnscrollDownChat() {
  var chatend = $(".chat");
  chatend.scrollTop(chatend.prop("scrollHeight"));
  return false;
}

// al cargar pag muevo para abajo el scroll del chat
fnscrollDownChat();




//=============================================
//  CAMBIAR IDIOMA
//=============================================

function fnCambiaIdioma(movil,idioma) {
  fnCambiarBandera(movil,idioma);
  fnSelectIdioma(movil,idioma);
}


// Excuchar evento click de idioma: cambia bandera + idioma en input hidden
$("#iphone").find('.idioma .submenu a').on('click tap',function(e){
  var movil = "iphone";
  var linkClass = e.target.className;
  fnCambiaIdioma(movil,linkClass);
  return false;
});

$("#android").find('.idioma .submenu a').on('click vclick',function(e){
  var movil = "android";
  var linkClass = e.target.className;
  fnCambiaIdioma(movil,linkClass);
  return false;
});


// TEST EVENTO CLICK
// Excuchar evento click de idioma: cambia bandera + idioma en input hidden
$("#iphone").find('.selIdioma').on('change',function(e){
  var movil = "iphone";
  var idioma = $(e.target).val();
  if(idioma != "ini") {
    fnCambiaIdioma(movil,idioma);
    $(e.target).val("ini");
  };
});

$("#android").find('.selIdioma').on('change',function(e){
  var movil = "android";
  var idioma = $(e.target).val();

  if(idioma != "ini") {
    fnCambiaIdioma(movil,idioma);
    $(e.target).val("ini");
  };
});


// Funcion cambiar bandera pais
function fnCambiarBandera(movil,idioma){
  var urlflag = ObjIdiomas[idioma].urlimg;
  $('#'+movil).find('.idioma-flag').attr('src',urlflag);

};

// Funcion selecciona idioma de cada movil en campo input hidden
function fnSelectIdioma(movil,idioma){
  var idiomacampo = $("#"+movil+" input[type='hidden']");
  idiomacampo.attr('value',idioma);
};








//=============================================
//  ENVIAR MENSAJES
//=============================================


// Escuchar evento click de enviar mensaje: escribe mensaje
$("#iphone").find('.escribir a').on('click tap',function(evt){
  var movil = "iphone";
  fnSendMsg(movil);
  fnscrollDownChat();
});

$("#android").find('.escribir a').on('click tap',function(evt){
  var movil = "android";
  fnSendMsg(movil);
  fnscrollDownChat();
});

// Escuchar evento keypress
$("#iphone").find('.escribir input').keypress(function( event ) {

  //  $("#log").text('Key pressed: ' + event.keyCode);
  if ( event.which == 13 ) {
    event.preventDefault();
    var movil = "iphone";
    fnSendMsg(movil);
    fnscrollDownChat();
  }
});
$("#android").find('.escribir input').keypress(function( event ) {
  if ( event.which == 13 ) {
    event.preventDefault();
    var movil = "android";
    fnSendMsg(movil);
    fnscrollDownChat();
  }
});





//funcion agregar texto al chat
function fnSendMsg(movil){
  // identifica moviel que envía y el que recibe
  var idMovilEnvia = "#"+movil;
  var idMovilRecibe = "#"+fnOtroMovil(movil);

  // obtiene los textos de los mensajes
  var msgEnvia = $(idMovilEnvia).find('.escribir input').val().trim();
  var msgRecibe = fnTextoIdioma(movil,msgEnvia);

  // validar si encuentró mesaje correcto o no.
  if(msgRecibe == "error"){
    $('#myModal').find('span:first').text(msgEnvia);
    $('#myModal').foundation('open');
  }
  else{

    //    var chatEnvia = '<div class="chat-list-msg"><span>'+msgEnvia+'</span><div class="bubble-arrow"></div></div>';
    //    var chatRecibe = '<div class="chat-list-msg recibido"><span>'+msgRecibe+'</span><div class="bubble-arrow recibido"></div></div>';


    $(idMovilEnvia).find('.chat-list').append(fnBurbujaDialogo(msgEnvia,1));
    $(idMovilRecibe).find('.chat-list').append(fnBurbujaDialogo(msgRecibe,0));
  }
  $(idMovilEnvia).find('.escribir input').val("");

}

// funcion crear burbuja con de dialogo
//enviar: dire==1 -- recibe: dire==0
function fnBurbujaDialogo(msg,dire) {
  var recibido = (dire === 0) ? "" : " recibido";
  var chatresultado = '<div class="chat-list-msg'+recibido+'"><span>'+msg+'</span><div class="bubble-arrow'+recibido+'"></div></div>';
  return chatresultado;
};


// obtener texto de idioma
function fnTextoIdioma(movil,msgIn){
  // obtyener valores de idioma
  var idiomaIn = $("#"+movil+" input[type='hidden']").val();
  var idiomaOut = $("#"+fnOtroMovil(movil)+" input[type='hidden']").val();

  // encontrar palabras (indistinto mayuscula o minuscula)
  var msgTemp ="";
  jQuery.grep(ObjIdiomas[idiomaIn].palabras, function (n,a) {
    if (n.toLocaleLowerCase() === msgIn.toLocaleLowerCase()) {
      msgTemp = ObjIdiomas[idiomaOut].palabras[a];
    }
  });

  // si no encuentra palabra: error.
  if(msgTemp==="") {msgTemp="error";}

  return msgTemp;
}








